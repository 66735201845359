import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import countryList from "./CountryData";

function EditApplication({ data, show }) {
  const [username, setUsername] = useState(null);
  const [applicationType, setApplicationType] = useState(null);
  const [applicationNumber, setApplicationNumber] = useState(null);
  const [applicantName, setApplicantName] = useState(null);
  const [dateOfSubmit, setDateOfSubmit] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [messages, setMessages] = useState(null);
  const [action, setAction] = useState(null);
  const [UCI, setUCI] = useState(null);
  const [country, setCountry] = useState(null);
  const [passportNumber, setPassportNumber] = useState(null);
  const [biometricsNumber, setBiometricsNumber] = useState(null);
  const [DOBEnroll, setDOBEnroll] = useState(null);
  const [lmia, setLmia] = useState(null);
  const [province, setProvince] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [expiry, setExpiry] = useState(null);
  const [msg1Subject, setMsg1Subject] = useState(null);
  const [msg2Subject, setMsg2Subject] = useState(null);
  const [msg3Subject, setMsg3Subject] = useState(null);
  const [msg4Subject, setMsg4Subject] = useState(null);
  const [msg5Subject, setMsg5Subject] = useState(null);
  const [msg6Subject, setMsg6Subject] = useState(null);
  const [msg7Subject, setMsg7Subject] = useState(null);
  const [msg8Subject, setMsg8Subject] = useState(null);
  const [msg9Subject, setMsg9Subject] = useState(null);
  const [msg10Subject, setMsg10Subject] = useState(null);
  const [msg1sent, setMsg1sent] = useState(null);
  const [msg2sent, setMsg2sent] = useState(null);
  const [msg3sent, setMsg3sent] = useState(null);
  const [msg4sent, setMsg4sent] = useState(null);
  const [msg5sent, setMsg5sent] = useState(null);
  const [msg6sent, setMsg6sent] = useState(null);
  const [msg7sent, setMsg7sent] = useState(null);
  const [msg8sent, setMsg8sent] = useState(null);
  const [msg9sent, setMsg9sent] = useState(null);
  const [msg10sent, setMsg10sent] = useState(null);
  const [msg1read, setMsg1read] = useState(null);
  const [msg2read, setMsg2read] = useState(null);
  const [msg3read, setMsg3read] = useState(null);
  const [msg4read, setMsg4read] = useState(null);
  const [msg5read, setMsg5read] = useState(null);
  const [msg6read, setMsg6read] = useState(null);
  const [msg7read, setMsg7read] = useState(null);
  const [msg8read, setMsg8read] = useState(null);
  const [msg9read, setMsg9read] = useState(null);
  const [msg10read, setMsg10read] = useState(null);

  console.log(applicationType);

  function Delete() {
    setErrorMsg("Deleting...");
    axios
      .post("https://canada-main.onrender.com/delete-application", {
        ID: data._id,
      })
      .then((res) => {
        setErrorMsg(res.data["message"]);
        setTimeout(() => {
          setShowPopup(true);
        }, 2000);
      })
      .catch((error) => {
        setErrorMsg(error.response.data["message"]);
        setTimeout(() => {
          setShowPopup(true);
        }, 2000);
      });
  }

  function Update() {
    setErrorMsg("Submitting...");
    axios
      .post("https://canada-main.onrender.com/update-application", {
        ID: data._id,
        username: username !== null ? username : data.username,
        atype: applicationType !== null ? applicationType : data.atype,
        anumber: applicationNumber !== null ? applicationNumber : data.anumber,
        aname: applicantName !== null ? applicantName : data.aname,
        datesubmit: dateOfSubmit !== null ? dateOfSubmit : data.datesubmit,
        currentstatus:
          currentStatus !== null ? currentStatus : data.currentstatus,
        messages: messages !== null ? messages : data.messages,
        action: action !== null ? action : data.action,
        uci: UCI !== null ? UCI : data.uci,
        pNumber: passportNumber !== null ? passportNumber : data.pNumber,
        country: country !== null ? country : data.country,
        bnumber: biometricsNumber !== null ? biometricsNumber : data.bnumber,
        dobenroll: DOBEnroll !== null ? DOBEnroll : data.dobenroll,
        lmia: lmia !== null ? lmia : data.lmia,
        province: province !== null ? province : data.province,
        edate: expiry !== null ? expiry : data.edate,
        msg1: [msg1Subject || data.msg1[0], msg1sent || data.msg1[1], msg1read || data.msg1[2]],
        msg2: [msg2Subject || data.msg2[0], msg2sent || data.msg2[1], msg2read || data.msg2[2]],
        msg3: [msg3Subject || data.msg3[0], msg3sent || data.msg3[1], msg3read || data.msg3[2]],
        msg4: [msg4Subject || data.msg4[0], msg4sent || data.msg4[1], msg4read || data.msg4[2]],
        msg5: [msg5Subject || data.msg5[0], msg5sent || data.msg5[1], msg5read || data.msg5[2]],
        msg6: [msg6Subject || data.msg6[0], msg6sent || data.msg6[1], msg6read || data.msg6[2]],
        msg7: [msg7Subject || data.msg7[0], msg7sent || data.msg7[1], msg7read || data.msg7[2]],
        msg8: [msg8Subject || data.msg8[0], msg8sent || data.msg8[1], msg8read || data.msg8[2]],
        msg9: [msg9Subject || data.msg9[0], msg9sent || data.msg9[1], msg9read || data.msg9[2]],
        msg10: [msg10Subject || data.msg10[0], msg10sent || data.msg10[1], msg10read || data.msg10[2]],
      })
      .then((res) => {
        setErrorMsg(res.data["message"]);
        setTimeout(() => {
          setShowPopup(true);
        }, 2000);
      })
      .catch((error) => {
        setErrorMsg(error.response.data["message"]);
        setTimeout(() => {
          setShowPopup(true);
        }, 2000);
      });
  }

  return (
    <Main>
      <div className="ctn">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Application Type</th>
              <th>Application Number</th>
              <th>Applicant Name</th>
              <th>Date Submitted</th>
              <th>Current Status</th>
              <th>Messages</th>
              <th>Action</th>
              <th>UCI</th>
              {/* <th>Passport Number</th>
              <th>Country</th> */}
              <th>Biometrics Number</th>
              <th>Date of Biometrics</th>
              <th>Expiry Date</th>
              <th>Message 1</th>
              <th>Message 2</th>
              <th>Message 3</th>
              <th>Message 4</th>
              <th>Message 5</th>
              <th>Message 6</th>
              <th>Message 7</th>
              <th>Message 8</th>
              <th>Message 9</th>
              <th>Message 10</th>
              {/* <th>LMIA Validity</th>
              <th>Province of Employment</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <input
                type="text"
                value={username !== null ? username : data.username}
                onChange={(e) => setUsername(e.target.value)}
              />

              <input
                type="text"
                value={applicationType !== null ? applicationType : data.atype}
                onChange={(e) => setApplicationType(e.target.value)}
              />

              <input
                type="text"
                value={
                  applicationNumber !== null ? applicationNumber : data.anumber
                }
                onChange={(e) => setApplicationNumber(e.target.value)}
              />

              <input
                type="text"
                value={applicantName !== null ? applicantName : data.aname}
                onChange={(e) => setApplicantName(e.target.value)}
              />

              <input
                type="text"
                value={dateOfSubmit !== null ? dateOfSubmit : data.datesubmit}
                onChange={(e) => setDateOfSubmit(e.target.value)}
              />

              <input
                type="text"
                value={
                  currentStatus !== null ? currentStatus : data.currentstatus
                }
                onChange={(e) => setCurrentStatus(e.target.value)}
              />

              <input
                type="text"
                value={messages !== null ? messages : data.messages}
                onChange={(e) => setMessages(e.target.value)}
              />

              <input
                type="text"
                value={action !== null ? action : data.action}
                onChange={(e) => setAction(e.target.value)}
              />

              <input
                type="text"
                value={UCI !== null ? UCI : data.uci}
                onChange={(e) => setUCI(e.target.value)}
              />

              {/* <input
                type="text"
                value={passportNumber !== null ? passportNumber : data.pNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />

              <select
                value={country !== null ? country : data.country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryList.map((country, index) => (
                  <option key={index}>{country}</option>
                ))}
              </select> */}

              <input
                type="text"
                value={
                  biometricsNumber !== null ? biometricsNumber : data.bnumber
                }
                onChange={(e) => setBiometricsNumber(e.target.value)}
              />

              <input
                type="text"
                value={DOBEnroll !== null ? DOBEnroll : data.dobenroll}
                onChange={(e) => setDOBEnroll(e.target.value)}
              />

              <input
                type="text"
                value={expiry !== null ? expiry : data.edate}
                onChange={(e) => setExpiry(e.target.value)}
              />

              {/* <input
                type="text"
                value={lmia !== null ? lmia : data.lmia}
                onChange={(e) => setLmia(e.target.value)}
              />

              <input
                type="text"
                value={province !== null ? province : data.province}
                onChange={(e) => setProvince(e.target.value)}
              /> */}

              <div className="input_row">
                <input
                  type="text"
                  value={msg1Subject !== null ? msg1Subject : data.msg1[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg1Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg1sent !== null ? msg1sent : data.msg1[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg1sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg1read !== null ? msg1read : data.msg1[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg1read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg2Subject !== null ? msg2Subject : data.msg2[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg2Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg2sent !== null ? msg2sent : data.msg2[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg2sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg2read !== null ? msg2read : data.msg2[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg2read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg3Subject !== null ? msg3Subject : data.msg3[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg3Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg3sent !== null ? msg3sent : data.msg3[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg3sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg3read !== null ? msg3read : data.msg3[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg3read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg4Subject !== null ? msg4Subject : data.msg4[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg4Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg4sent !== null ? msg4sent : data.msg4[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg4sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg4read !== null ? msg4read : data.msg4[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg4read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg5Subject !== null ? msg5Subject : data.msg5[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg5Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg5sent !== null ? msg5sent : data.msg5[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg5sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg5read !== null ? msg5read : data.msg5[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg5read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg6Subject !== null ? msg6Subject : data.msg6[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg6Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg6sent !== null ? msg6sent : data.msg6[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg6sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg6read !== null ? msg6read : data.msg6[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg6read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg7Subject !== null ? msg7Subject : data.msg7[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg7Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg7sent !== null ? msg7sent : data.msg7[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg7sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg7read !== null ? msg7read : data.msg7[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg7read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg8Subject !== null ? msg8Subject : data.msg8[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg8Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg8sent !== null ? msg8sent : data.msg8[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg8sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg8read !== null ? msg8read : data.msg8[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg8read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg9Subject !== null ? msg9Subject : data.msg9[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg9Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg9sent !== null ? msg9sent : data.msg9[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg9sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg9read !== null ? msg9read : data.msg9[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg9read(e.target.value)}
                />
              </div>
              <div className="input_row">
                <input
                  type="text"
                  value={msg10Subject !== null ? msg10Subject : data.msg10[0]}
                  placeholder="Subject"
                  onChange={(e) => setMsg10Subject(e.target.value)}
                />
                <input
                  type="text"
                  value={msg10sent !== null ? msg10sent : data.msg10[1]}
                  placeholder="Date Sent"
                  onChange={(e) => setMsg10sent(e.target.value)}
                />
                <input
                  type="text"
                  value={msg10read !== null ? msg10read : data.msg10[2]}
                  placeholder="Date Read"
                  onChange={(e) => setMsg10read(e.target.value)}
                />
              </div>
            </tr>
          </tbody>
        </table>
        <div>
          <button onClick={Delete}>Delete</button>
          <button className="button" onClick={Update}>
            Update
          </button>
          <button className="button" onClick={() => show(false)}>
            Close
          </button>
        </div>
      </div>
      {showPopup && (
        <p onClick={() => setShowPopup(false)} className="popup">
          {errorMsg}
        </p>
      )}
    </Main>
  );
}

export default EditApplication;

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: white;
  display: grid;
  place-items: center;

  .ctn {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  table {
    margin-top: 25px;
    border: 2px solid #999;
    font-size: 0.9rem;
    display: flex;
    border-collapse: collapse !important;
  }
  thead,
  tbody {
    border: none !important;
  }

  th,
  input,
  select {
    width: 250px;
    height: 35px;
    border: 1px solid #ddd;
    display: grid;
    place-items: center;
    justify-content: left;
    padding-left: 20px;
    outline: none;
  }

  tr {
    display: flex;
    flex-direction: column;
  }

  button {
    font-size: 1rem;
    margin: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #f7786b;
    background-color: #f7786b;
    color: black;
    cursor: pointer;
  }

  .button {
    border: 1px solid #418af2;
    background-color: #418af2;
    color: white;
  }

  .input_row {
    display: flex;
    align-items: center;
  }

  .label {
    width: 250px;
    font-weight: 700;
  }
`;
